export const parks = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      properties: {
        category: 'Bauwerk',
        name: 'Rauchstraße Berlin',
        address: 'Rauchstraße',
        cityzip: '10787 Berlin',
        description:
          'Bauherr/Auftraggeber: "Neue Heimat" und IBA, Berlin, Groth und Gralfs.',
        infotext:
          'Bauherr/Auftraggeber: "Neue Heimat" und IBA, Berlin, Groth und Gralfs.',
        info: 'Bauherr/Auftraggeber: "Neue Heimat" und IBA, Berlin, Groth und Gralfs.',
        website: 'website.com',
        person: 'Hans Hollein',
        imagetmb:
          'https://www.hollein.com/var/ezwebin_site/storage/images/projekte/wohnbau-rauchstrasse-berlin/135_rauchstrasse_berlin_07.jpg/5517-1-ger-DE/135_RAUCHSTRASSE_BERLIN_07.jpg_projectimage.jpg',
        image:
          'https://www.hollein.com/var/ezwebin_site/storage/images/projekte/wohnbau-rauchstrasse-berlin/135_rauchstrasse_berlin_07.jpg/5517-1-ger-DE/135_RAUCHSTRASSE_BERLIN_07.jpg_projectimage.jpg',
        link: 'https://www.hollein.com/ger/Projekte/Rauchstrasse-Berlin',
        'marker-color': '#7e7e7e',
        'marker-size': 'medium',
        'marker-symbol': 'park',
      },
      geometry: {
        type: 'Point',
        coordinates: [13.22087, 52.56018],
      },
    },
    {
      type: 'Feature',
      properties: {
        category: 'Bauwerk',
        name: 'Landesvertretung Bayern',
        address: 'Behrenstraße 21/22',
        cityzip: '10117 Berlin',
        description:
          'Für die Bayerische Landesvertretung in Berlin hat BauWerke die Besprechungs-, Konferenz- und Versammlungsräume sowie ausgewählte Büros neu gestaltet. Der sanierte Altbau mit seiner Beständigkeit, Solidität und Großzügigkeit  ist gleichzeitig zweckmäßig und zeitgemäß organisiert. Er dient aktuellen und anspruchsvollen politischen, wirtschaftlichen und kulturellen Zwecken. Die Innenraumgestaltung soll diesen Charakterzügen Rechnung tragen. Wohn- und Geschäftshaus Spichernstraße, Berlin',
        infotext:
          'Für die Bayerische Landesvertretung in Berlin hat BauWerke die Besprechungs-, Konferenz- und Versammlungsräume sowie ausgewählte Büros neu gestaltet. Der sanierte Altbau mit seiner Beständigkeit, Solidität und Großzügigkeit  ist gleichzeitig zweckmäßig und zeitgemäß organisiert. Er dient aktuellen und anspruchsvollen politischen, wirtschaftlichen und kulturellen Zwecken. Die Innenraumgestaltung soll diesen Charakterzügen Rechnung tragen. Wohn- und Geschäftshaus Spichernstraße, Berlin',
        info: 'Für die Bayerische Landesvertretung in Berlin hat BauWerke die Besprechungs-, Konferenz- und Versammlungsräume sowie ausgewählte Büros neu gestaltet. Der sanierte Altbau mit seiner Beständigkeit, Solidität und Großzügigkeit  ist gleichzeitig zweckmäßig und zeitgemäß organisiert. Er dient aktuellen und anspruchsvollen politischen, wirtschaftlichen und kulturellen Zwecken. Die Innenraumgestaltung soll diesen Charakterzügen Rechnung tragen. Wohn- und Geschäftshaus Spichernstraße, Berlin',
        website: 'website.com',
        person: 'Fritz Breitenthaler',
        imagetmb:
          'https://bauwerke.org/wp-content/uploads/2021/07/Bild01-3.jpg',
        image: 'https://bauwerke.org/wp-content/uploads/2021/07/Bild01-3.jpg',
        link: 'https://bauwerke.org/projekte/?cat=all&projekt=485',
        'marker-color': '#7e7e7e',
        'marker-size': 'medium',
        'marker-symbol': 'park',
      },
      geometry: {
        type: 'Point',
        coordinates: [13.38756, 52.515431],
      },
    },
    {
      type: 'Feature',
      properties: {
        category: 'Bauwerk',
        name: 'Bundespräsidialamt Berlin',
        address: 'Spreeweg 1',
        cityzip: '10557 Berlin',
        description:
          'Bauliche Unterhaltung der Liegenschaften des Bundespräsidialamtes, Berlin. Schloß Bellevue, Bundespräsidialamt (Neubau), Residenz des Bundespräsidenten und Gästehäuser. Leistungen: Architekten- und Ingenieurleistungen (Planung und Ausführung)',
        infotext:
          'Bauliche Unterhaltung der Liegenschaften des Bundespräsidialamtes, Berlin. Schloß Bellevue, Bundespräsidialamt (Neubau), Residenz des Bundespräsidenten und Gästehäuser. Leistungen: Architekten- und Ingenieurleistungen (Planung und Ausführung)',
        info: 'Bauliche Unterhaltung der Liegenschaften des Bundespräsidialamtes, Berlin. Schloß Bellevue, Bundespräsidialamt (Neubau), Residenz des Bundespräsidenten und Gästehäuser. Leistungen: Architekten- und Ingenieurleistungen (Planung und Ausführung)',
        website: 'website.com',
        person: 'Fritz Breitenthaler',
        imagetmb:
          'https://bauwerke.org/wp-content/uploads/2021/07/Bild03-5.jpg',
        image: 'https://bauwerke.org/wp-content/uploads/2021/07/Bild03-5.jpg',
        link: 'https://bauwerke.org/projekte/?cat=all&projekt=485',
        'marker-color': '#7e7e7e',
        'marker-size': 'medium',
        'marker-symbol': 'park',
      },
      geometry: {
        type: 'Point',
        coordinates: [13.35112, 52.51631],
      },
    },
    {
      type: 'Feature',
      properties: {
        category: 'Bauwerk',
        name: 'Nordische Botschaften',
        address: 'Rauchstraße 1',
        cityzip: '10787 Berlin',
        description:
          'Die Nordischen Botschaften sind in einem architektonischen Ensemble verbunden. Ein grünes Kupferband umarmt die fünf Gebäude und symbolisiert die Zusammengehörigkeit der nordischen Länder.',
        infotext:
          'Die Nordischen Botschaften sind in einem architektonischen Ensemble verbunden. Ein grünes Kupferband umarmt die fünf Gebäude und symbolisiert die Zusammengehörigkeit der nordischen Länder.',
        info: 'Die Nordischen Botschaften sind in einem architektonischen Ensemble verbunden. Ein grünes Kupferband umarmt die fünf Gebäude und symbolisiert die Zusammengehörigkeit der nordischen Länder.',
        website: 'website.com',
        person: 'Alfred Berger',
        imagetmb:
          'https://www.berlin.de/binaries/asset/image_assets/3811553/source/1444122018/624x468/',
        image:
          'https://www.berlin.de/binaries/asset/image_assets/3811553/source/1444122018/624x468/',
        link: 'https://www.berlin.de/sehenswuerdigkeiten/3560479-3558930-nordische-botschaften.html',
        'marker-color': '#7e7e7e',
        'marker-size': 'medium',
        'marker-symbol': 'park',
      },
      geometry: {
        type: 'Point',
        coordinates: [13.350789, 52.508599],
      },
    },
    {
      type: 'Feature',
      properties: {
        category: 'Bauwerk',
        name: 'ARD Hauptstadtstudio',
        address: 'Wilhelmstraße 67a',
        cityzip: '10117 Berlin',
        description:
          'Im Stadtgefüge ergänzt und stabilisiert der Baukörper einen historischen Baublock an der Spree. Im Inneren des Gebäudes sind Flächen für Verwaltung, Redaktions-und Studioarbeiten um eine zentrale Redaktionshalle herum angeordnet. Mit einer großen Freitreppe, Verbindungsbrücken und Galerien erschlossen, bietet diese Halle den atmosphärischen Platz für Inszenierungen aller Art.',
        infotext:
          'Im Stadtgefüge ergänzt und stabilisiert der Baukörper einen historischen Baublock an der Spree. Im Inneren des Gebäudes sind Flächen für Verwaltung, Redaktions-und Studioarbeiten um eine zentrale Redaktionshalle herum angeordnet. Mit einer großen Freitreppe, Verbindungsbrücken und Galerien erschlossen, bietet diese Halle den atmosphärischen Platz für Inszenierungen aller Art.',
        info: 'Im Stadtgefüge ergänzt und stabilisiert der Baukörper einen historischen Baublock an der Spree. Im Inneren des Gebäudes sind Flächen für Verwaltung, Redaktions-und Studioarbeiten um eine zentrale Redaktionshalle herum angeordnet. Mit einer großen Freitreppe, Verbindungsbrücken und Galerien erschlossen, bietet diese Halle den atmosphärischen Platz für Inszenierungen aller Art.',
        website: 'website.com',
        person: 'Ortner & Ortner',
        imagetmb:
          'https://ortner-ortner.com/thumbs/baukunst/projekte/kultur/ard-hauptstadtstudio-berlin/1ard_2-1900x1488-q70.jpg',
        image:
          'https://ortner-ortner.com/thumbs/baukunst/projekte/kultur/ard-hauptstadtstudio-berlin/1ard_2-1900x1488-q70.jpg',
        link: 'https://ortner-ortner.com/de/baukunst/projekte/kultur/ard-hauptstadtstudio-berlin',
        'marker-color': '#7e7e7e',
        'marker-size': 'medium',
        'marker-symbol': 'park',
      },
      geometry: {
        type: 'Point',
        coordinates: [13.380717, 52.518801],
      },
    },
    {
      type: 'Feature',
      properties: {
        category: 'Bauwerk',
        name: 'Bürohaus am Pariser Platz',
        address: 'Pariser Platz',
        cityzip: '10117 Berlin',
        description:
          'Der Pariser Platz bildet im Zusammenspiel mit dem Brandenburger Tor ein städtebauliches Ensemble, welches sich, wie vor dem zweiten Weltkrieg, wieder zum Foyer der Hauptstadt entwickeln wird. Das Gebäude ist das einzige bürgerliche Stadtpalais am Platz, mit Gastronomie- und Geschäftsflächen entlang der Straße und Büro-und Wohnflächen in den Obergeschossen.',
        infotext:
          'Der Pariser Platz bildet im Zusammenspiel mit dem Brandenburger Tor ein städtebauliches Ensemble, welches sich, wie vor dem zweiten Weltkrieg, wieder zum Foyer der Hauptstadt entwickeln wird. Das Gebäude ist das einzige bürgerliche Stadtpalais am Platz, mit Gastronomie- und Geschäftsflächen entlang der Straße und Büro-und Wohnflächen in den Obergeschossen.',
        info: 'Der Pariser Platz bildet im Zusammenspiel mit dem Brandenburger Tor ein städtebauliches Ensemble, welches sich, wie vor dem zweiten Weltkrieg, wieder zum Foyer der Hauptstadt entwickeln wird. Das Gebäude ist das einzige bürgerliche Stadtpalais am Platz, mit Gastronomie- und Geschäftsflächen entlang der Straße und Büro-und Wohnflächen in den Obergeschossen.',
        website: 'website.com',
        person: 'Ortner & Ortner',
        imagetmb:
          'https://ortner-ortner.com/thumbs/baukunst/projekte/wohnen/pariser-platz-berlin/1pap_10-1900x1503-q70.jpg',
        image:
          'https://ortner-ortner.com/thumbs/baukunst/projekte/wohnen/pariser-platz-berlin/1pap_10-1900x1503-q70.jpg',
        link: 'https://ortner-ortner.com/de/baukunst/projekte/wohnen/pariser-platz-berlin',
        'marker-color': '#7e7e7e',
        'marker-size': 'medium',
        'marker-symbol': 'park',
      },
      geometry: {
        type: 'Point',
        coordinates: [13.378268, 52.516223],
      },
    },
    {
      type: 'Feature',
      properties: {
        category: 'Bauwerk',
        name: 'Kindertagesstätte Bundestag ',
        address: 'Otto-von-Bismarck-Allee 2',
        cityzip: '10557 Berlin',
        description:
          'Nördlich des Paul-Löbe-Hauses liegt die 1998 bis 1999 gebaute Betriebskindertagesstätte des Deutschen Bundestages. Sie wurde vom Wiener Architekten Gustav Peichl entworfen, der aus einem Wettbewerb als 1. Preisträger hervorgegangen war. Unmittelbar an der Spree gelegen, ruft sie Assoziationen an ein elegant-verspieltes Schiff wach, das seitwärts des „Bandes des Bundes“ angelegt hat. Die starke Farbigkeit, einfache geometrische Grundstrukturen und verspielte architektonische Elemente, wie die Doppelkugeln auf dem Dach, die den Kindern als Wohnhöhlen für den Mittagsschlaf zur Verfügung stehen, spiegeln die Funktion des Gebäudes als eines fröhlich-heiteren Hauses für Kinder wider.',
        infotext:
          'Nördlich des Paul-Löbe-Hauses liegt die 1998 bis 1999 gebaute Betriebskindertagesstätte des Deutschen Bundestages. Sie wurde vom Wiener Architekten Gustav Peichl entworfen, der aus einem Wettbewerb als 1. Preisträger hervorgegangen war. Unmittelbar an der Spree gelegen, ruft sie Assoziationen an ein elegant-verspieltes Schiff wach, das seitwärts des „Bandes des Bundes“ angelegt hat. Die starke Farbigkeit, einfache geometrische Grundstrukturen und verspielte architektonische Elemente, wie die Doppelkugeln auf dem Dach, die den Kindern als Wohnhöhlen für den Mittagsschlaf zur Verfügung stehen, spiegeln die Funktion des Gebäudes als eines fröhlich-heiteren Hauses für Kinder wider.',
        info: 'Nördlich des Paul-Löbe-Hauses liegt die 1998 bis 1999 gebaute Betriebskindertagesstätte des Deutschen Bundestages. Sie wurde vom Wiener Architekten Gustav Peichl entworfen, der aus einem Wettbewerb als 1. Preisträger hervorgegangen war. Unmittelbar an der Spree gelegen, ruft sie Assoziationen an ein elegant-verspieltes Schiff wach, das seitwärts des „Bandes des Bundes“ angelegt hat. Die starke Farbigkeit, einfache geometrische Grundstrukturen und verspielte architektonische Elemente, wie die Doppelkugeln auf dem Dach, die den Kindern als Wohnhöhlen für den Mittagsschlaf zur Verfügung stehen, spiegeln die Funktion des Gebäudes als eines fröhlich-heiteren Hauses für Kinder wider.',

        website: 'website.com',
        person: 'Gustav Peichl',
        imagetmb:
          'https://www.bundestag.de/resource/image/235608/16x9/943/531/9d7c30460d35b01929cbb34001c2f388/1682F8F7CAD4695F2BDCA014D4E9560B/foto1.jpg',
        image:
          'https://www.bundestag.de/resource/image/235608/16x9/943/531/9d7c30460d35b01929cbb34001c2f388/1682F8F7CAD4695F2BDCA014D4E9560B/foto1.jpg',
        link: 'https://www.bundestag.de/besuche/architektur/gebaeude/kindertagesstaette/kindertagesstaette-198806',
        'marker-color': '#7e7e7e',
        'marker-size': 'medium',
        'marker-symbol': 'park',
      },
      geometry: {
        type: 'Point',
        coordinates: [13.375133, 52.521033],
      },
    },
    {
      type: 'Feature',
      properties: {
        category: 'Bauwerk',
        name: 'Bundespresse- Konferenzzentrum',
        address: 'Pressehaus/0103, Schiffbauerdamm 40',
        cityzip: '10117 Berlin',
        description:
          'Die Architekturhaltung des 6 und 2-Staffelgeschosse hohen Gebäudes mit der Berlin-typischen 22m hohen Trauflinie entwickelt sich aus dem Ausdruckswillen der Parlamentsschiene: sie will keineswegs übertrumpfen, sondern vielmehr klare Verhältnisse schaffen, indem sie sich zurückhält und sich gleichzeitig eine Strenge auferlegt, die Grundlage jeder Freiheit, somit vielschichtige Entfaltung nach innen für die Gebäudeorganisation, sowie nach außen, für den Stadtraum sein sollte. Der Inhalt des Ganzen, die Bundespressekonferenz - hat als Ereignis den Vortritt, ohne auf den formalen Ausdruck zu verzichten. Der Pressesaal mit seinen 5,40m hohen Fenstern zum Schiffbauerdamm ist formale Übersetzung des Inhalts und verbindet die Außenwelt mit dem journalistischen Innenleben.',
        infotext:
          'Die Architekturhaltung des 6 und 2-Staffelgeschosse hohen Gebäudes mit der Berlin-typischen 22m hohen Trauflinie entwickelt sich aus dem Ausdruckswillen der Parlamentsschiene: sie will keineswegs übertrumpfen, sondern vielmehr klare Verhältnisse schaffen, indem sie sich zurückhält und sich gleichzeitig eine Strenge auferlegt, die Grundlage jeder Freiheit, somit vielschichtige Entfaltung nach innen für die Gebäudeorganisation, sowie nach außen, für den Stadtraum sein sollte. Der Inhalt des Ganzen, die Bundespressekonferenz - hat als Ereignis den Vortritt, ohne auf den formalen Ausdruck zu verzichten. Der Pressesaal mit seinen 5,40m hohen Fenstern zum Schiffbauerdamm ist formale Übersetzung des Inhalts und verbindet die Außenwelt mit dem journalistischen Innenleben.',
        info: 'Die Architekturhaltung des 6 und 2-Staffelgeschosse hohen Gebäudes mit der Berlin-typischen 22m hohen Trauflinie entwickelt sich aus dem Ausdruckswillen der Parlamentsschiene: sie will keineswegs übertrumpfen, sondern vielmehr klare Verhältnisse schaffen, indem sie sich zurückhält und sich gleichzeitig eine Strenge auferlegt, die Grundlage jeder Freiheit, somit vielschichtige Entfaltung nach innen für die Gebäudeorganisation, sowie nach außen, für den Stadtraum sein sollte. Der Inhalt des Ganzen, die Bundespressekonferenz - hat als Ereignis den Vortritt, ohne auf den formalen Ausdruck zu verzichten. Der Pressesaal mit seinen 5,40m hohen Fenstern zum Schiffbauerdamm ist formale Übersetzung des Inhalts und verbindet die Außenwelt mit dem journalistischen Innenleben.',
        website: 'website.com',
        person: 'Nalbach & Nalbach',
        imagetmb: 'http://www.nalbach-architekten.de/jpgs/arch23-02.jpg',
        image: 'http://www.nalbach-architekten.de/jpgs/arch23-02.jpg',
        link: 'http://www.nalbach-architekten.de/arch23.htm',
        'marker-color': '#7e7e7e',
        'marker-size': 'medium',
        'marker-symbol': 'park',
      },
      geometry: {
        type: 'Point',
        coordinates: [13.377418, 52.52186],
      },
    },
    {
      type: 'Feature',
      properties: {
        category: 'Kultur',
        name: 'Österreichische Botschaft Berlin',
        address: 'Stauffenbergstraße 1, Berlin-Tiergarten',
        cityzip: '10785 Berlin ',
        description:
          'Eine Villa als Botschaft: Das Diplomatenviertel war einst ein Villenviertel und so entwarf Hans Hollein ein Gebäude, das typologisch daran anknüpfen sollte.',
        info: 'Eine Villa als Botschaft: Das Diplomatenviertel war einst ein Villenviertel und so entwarf Hans Hollein ein Gebäude, das typologisch daran anknüpfen sollte.',
        infotext:
          'Eine Villa als Botschaft: Das Diplomatenviertel war einst ein Villenviertel und so entwarf Hans Hollein ein Gebäude, das typologisch daran anknüpfen sollte.',
        website: 'https://www.bmeia.gv.at/oeb-berlin/',
        person: 'Hans Hollein',
        imagetmb:
          'https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Be_Austrian_Embassy_01.JPG/1280px-Be_Austrian_Embassy_01.JPG',
        image:
          'https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Be_Austrian_Embassy_01.JPG/1280px-Be_Austrian_Embassy_01.JPG',
        link: 'https://source.unsplash.com/MfNHW8vlbLs/300x200',
        'marker-color': '#7e7e7e',
        'marker-size': 'medium',
        'marker-symbol': 'park',
      },
      geometry: {
        type: 'Point',
        coordinates: [13.36386, 52.50947],
      },
    },
  ],
};

// 🏄 ICONS
export const iconCity = `
    <path class="e-marker__icon e-marker__icon--city" d="M41.1,68.7V58.5L36,53.4l-5.1,5.1v3.4H20.8v23.7h30.5V68.7H41.1z M27.5,82.2h-3.4v-3.4h3.4V82.2z M27.5,75.4h-3.4
	V72h3.4V75.4z M27.5,68.7h-3.4v-3.4h3.4V68.7z M37.7,82.2h-3.4v-3.4h3.4V82.2z M37.7,75.4h-3.4V72h3.4V75.4z M37.7,68.7h-3.4v-3.4
	h3.4V68.7z M37.7,61.9h-3.4v-3.4h3.4V61.9z M47.9,82.2h-3.4v-3.4h3.4V82.2z M47.9,75.4h-3.4V72h3.4V75.4z"/>
`;

export const iconBeach = `
    <path class="e-marker__icon e-marker__icon--beach" d="M38.3,74.8l2.9-2.9L54,84.8l-2.9,2.9L38.3,74.8z M46.8,63.3l5.7-5.7c-7.9-7.9-20.7-7.9-28.6,0C31.8,55,40.6,57.1,46.8,63.3z
     M23.9,57.6c-7.9,7.9-7.9,20.7,0,28.6l5.7-5.7C23.4,74.2,21.3,65.5,23.9,57.6z M23.9,57.6L23.9,57.6c-0.8,6,2.3,13.8,8.6,20.1
    L44,66.2C37.7,59.9,30,56.8,23.9,57.6z"/>
`;

export const iconMountain = `
    <path class="e-marker__icon e-marker__icon--mountain" d="M39.5,58.8l-6.6,8.8l5,6.7l-2.8,2.1c-3-3.9-7.9-10.5-7.9-10.5l-10.5,14h38.6L39.5,58.8z"/>
`;

export const iconJungle = `
    <g class="e-marker__icon e-marker__icon--jungle">
	    <path d="M50.1,81.9c-1.7-2.8-2.8-6.1-5.6-8c1.6-0.2,3.2,0.1,4.6-0.5c2.3-0.9,3.2-3.9,2.7-6.3c-0.5-2.5-2.1-4.5-3.7-6.5
		c-0.8-1.1-1.6-2.1-2.5-3.2c-1.3,2.9,0.1,6.6-1.4,9.4c-0.3,0.5-0.8,1-1.4,0.8c-0.6-0.3-0.3-1.3,0-1.9c1.4-2.5,1.8-5.6,1.3-8.4
		c-0.1-0.5-0.3-1.1-0.6-1.5c-0.3-0.4-0.8-0.7-1.3-0.9c-1.5-0.8-3.1-1.3-4.8-1.6c1.3,1.6,1.6,3.9,1.8,6c0.2,1.7,0.3,3.7-0.9,4.9
		c-0.6-0.3-1.1-0.9-1.1-1.6c-0.1-0.7,0-1.4,0.1-2.1c0.3-2,0.3-4.2-0.9-5.9c-1.5-2.4-5.1-3.1-7.5-1.5c1.9,1.6,3.3,3.8,3.9,6.2
		c0.1,0.6,0.1,1.6-0.6,1.6c-0.6,0.1-0.9-0.6-1.2-1.1c-0.6-1.5-1.1-3-1.7-4.4c-0.3-0.6-0.5-1.3-1.1-1.8c-1.3-0.9-3-0.1-4.5,0.3
		C23,54,22,53.9,21.2,53.7c0.7,1.6,0.8,3.5,0.4,5.3c-0.3,0.9-0.6,1.8-0.8,2.8c-0.2,0.9-0.1,2,0.5,2.8c0.8,1,2.3,1.1,3.7,0.9
		c2.6-0.3,5.3-1.1,7.7-0.4c-0.3,0.9-1.1,1.5-2,1.8c-3.5,1.6-7.8,1.8-11.5,0.4c1.3,0.7,2.8,1.4,3.5,2.8c0.3,0.6,0.4,1.3,0.8,1.9
		c0.5,0.9,1.5,1.5,2,2.4c0.4,0.6,0.6,1.3,0.9,2c0.4,0.6,0.9,1.3,1.6,1.3c0.5,0.1,0.9-0.2,1.4-0.4c2.3-1,4.7-1.8,7.2-2.5
		c0.6-0.2,1.5-0.3,1.8,0.4c-0.1,0.5-0.6,0.8-1.1,1.1c-2.7,1.3-5.5,2.3-8.4,3.1c1.8,1.3,3.7,2.5,5.8,3.3c2.1,0.8,4.5,0.9,6.5-0.1
		c2.6-1.2,4.2-4.2,3.7-7c1.7,1.6,2.8,3.8,3.9,5.9c1.1,2.1,2.2,4.1,3.2,6.2c0.2-0.4,0.4-0.7,0.6-1.1C52,85.1,51,83.4,50.1,81.9z
		 M33.2,71.4c-0.5,0.3-1.1,0.4-1.6,0.6c-0.8,0.2-1.5,0.3-2.3,0.3c1.6-0.7,3.2-1.3,4.8-1.9h-0.1c0.1-0.1,0.3,0,0.4,0.1
		C34.2,71,33.7,71.2,33.2,71.4z"/>
    </g>
`;
