import { extendTheme, type ThemeConfig } from '@chakra-ui/react';
import { styles } from './styles';
import { Button } from './components/button';

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

export const theme = extendTheme({
  config,
  styles,
  colors: {
    brand: {
      primary: '#00DDFF',
      greyPrimary: '#38383d',
      greySecondary: '#42414d',
      100: '#ff0000',
      80: '#ff1a1a',
    },
  },
  fonts: {
    body: 'Rubik, sans-serif',
  },
  components: {
    Button,
  },
});

export default theme;
