// import { MapboxStyleDefinition } from 'mapbox-gl-style-switcher';

// const version = 0.1;

const config = {
  mapboxAccessToken: process.env.REACT_APP_MAPBOX_TOKEN,
  geoserverURL: 'https://geoserver.telesis.at/geoserver',
  lng: 13.3686,
  lat: 52.51525,
  zoom: 13,
  pitch: 0,
  bearing: 0,
  maxPitch: 80,
  maxBounds: [
    [12.6, 52.3], // Southwest coordinates
    [14.3, 52.72], // Northeast coordinates
  ],
  styles: [
    {
      title: 'Straße',
      uri: 'mapbox://styles/mapbox/streets-v11',
    },
    {
      title: 'Luftbild',
      uri: 'mapbox://styles/telesisadmin/cl4pms4lq000t14mlx2b59b3o',
    },
    {
      title: 'Dark',
      uri: 'mapbox://styles/mapbox/dark-v9',
    },
    {
      title: 'Light',
      uri: 'mapbox://styles/mapbox/light-v9',
    },
  ],
  layers: {
    vectorTiles: {
      addSources: [
        // example for adding sources:
        // {
        //   id: 'id_name',
        //   type: 'layer_type', // 'raster', 'vector', 'geojson', ...
        //   tiles: [
        //     `https://example.com/wms?...`,
        //   ],
        //   minzoom: 0,
        //   maxzoom: 21,
        // },
        {
          id: 'Gebäude',
          type: 'vector',
          // use the tiles option to specify a WMTS tile source URL
          // https://docs.mapbox.com/mapbox-gl-js/style-spec/sources/
          tiles: [
            `https://geoserver.telesis.at/geoserver/gwc/service/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&LAYER=telesis:Hauptkonturflächen&propertyName=area,geom,id&STYLE=&TILEMATRIX=EPSG:900913:{z}&TILEMATRIXSET=EPSG:900913&FORMAT=application/vnd.mapbox-vector-tile&TILECOL={x}&TILEROW={y}`,
          ],
          minzoom: 15,
          maxzoom: 21,
        },
      ],
      addLayers: [
        {
          id: 'contour-line',
          type: 'line',
          source: 'composite',
          sourceLayer: 'contour',
          minzoom: 11,
          layout: {
            // Visibility is set to "none" by default, so we need to set it to "visible"
            visibility: 'none',
            // 'line-join': 'round',
            // 'line-cap': 'round',
          },
          filter: ['!=', ['get', 'index'], -1],
          metaData: {
            'mapbox:group': 'telesis',
          },
          paint: {
            'line-opacity': [
              'interpolate',
              ['linear'],
              ['zoom'],
              11,
              ['match', ['get', 'index'], [1, 2], 0.15, 0.25],
              12,
              ['match', ['get', 'index'], [1, 2], 0.3, 0.5],
            ],
            'line-color': 'hsl(100, 99%, 19%)',
            'line-width': [
              'interpolate',
              ['linear'],
              ['zoom'],
              13,
              ['match', ['get', 'index'], [1, 2], 0.5, 0.6],
              16,
              ['match', ['get', 'index'], [1, 2], 0.8, 1.2],
            ],
            'line-offset': [
              'interpolate',
              ['linear'],
              ['zoom'],
              13,
              ['match', ['get', 'index'], [1, 2], 1, 0.6],
              16,
              ['match', ['get', 'index'], [1, 2], 1.6, 1.2],
            ],
          },
        },
        {
          id: 'contour-label',
          type: 'symbol',
          source: 'composite',
          sourceLayer: 'contour',
          minzoom: 11,
          filter: [
            'any',
            ['==', ['get', 'index'], 10],
            ['==', ['get', 'index'], 5],
          ],
          layout: {
            // Visibility is set to "none" by default, so we need to set it to "visible"
            visibility: 'none',
            'text-field': ['concat', ['get', 'ele'], ' m'],
            'symbol-placement': 'line',
            'text-pitch-alignment': 'viewport',
            'text-max-angle': 25,
            'text-padding': 5,
            'text-font': ['DIN Pro Medium', 'Arial Unicode MS Regular'],
            'text-size': ['interpolate', ['linear'], ['zoom'], 15, 9.5, 20, 12],
          },
          metaData: {
            'mapbox:group': 'telesis',
          },
          paint: {
            'text-color': 'hsl(100, 86%, 21%)',
            'text-halo-width': 1,
            'text-halo-color': 'hsla(35, 16%, 100%, 0.5)',
          },
        },
        {
          id: 'landuse',
          type: 'fill',
          metadata: {
            'mapbox:featureComponent': 'land-and-water',
            'mapbox:group': 'Land & water, land',
          },
          source: 'composite',
          sourceLayer: 'landuse',
          minzoom: 5,
          filter: [
            'all',
            ['>=', ['to-number', ['get', 'sizerank']], 0],
            [
              'match',
              ['get', 'class'],
              ['park', 'airport', 'glacier', 'pitch', 'sand', 'facility'],
              true,
              'residential',
              ['step', ['zoom'], true, 12, false],
              ['agriculture', 'wood', 'grass', 'scrub'],
              true,
              'cemetery',
              true,
              'school',
              true,
              'hospital',
              true,
              'commercial_area',
              true,
              false,
            ],
            [
              '<=',
              [
                '-',
                ['to-number', ['get', 'sizerank']],
                ['interpolate', ['exponential', 1.5], ['zoom'], 12, 0, 18, 14],
              ],
              14,
            ],
          ],
          layout: { visibility: 'none' },
          paint: {
            'fill-color': [
              'interpolate',
              ['linear'],
              ['zoom'],
              15,
              [
                'match',
                ['get', 'class'],
                'park',
                [
                  'match',
                  ['get', 'type'],
                  ['garden', 'playground', 'zoo'],
                  'hsl(99, 35%, 73%)',
                  'hsl(99, 57%, 75%)',
                ],
                'airport',
                'hsl(230, 12%, 92%)',
                'cemetery',
                'hsl(81, 26%, 81%)',
                'glacier',
                'hsl(196, 60%, 85%)',
                'hospital',
                'hsl(340, 21%, 88%)',
                'pitch',
                'hsl(99, 58%, 70%)',
                'sand',
                'hsl(65, 46%, 89%)',
                'school',
                'hsl(50, 46%, 82%)',
                'commercial_area',
                'hsl(50, 60%, 90%)',
                'agriculture',
                'hsl(81, 26%, 81%)',
                ['wood', 'grass', 'scrub'],
                'hsl(81, 25%, 66%)',
                'residential',
                'hsl(35, 11%, 95%)',
                'hsl(35, 13%, 86%)',
              ],
              16,
              [
                'match',
                ['get', 'class'],
                'park',
                [
                  'match',
                  ['get', 'type'],
                  ['garden', 'playground', 'zoo'],
                  'hsl(99, 35%, 73%)',
                  'hsl(99, 57%, 75%)',
                ],
                'airport',
                'hsl(230, 26%, 90%)',
                'cemetery',
                'hsl(81, 26%, 81%)',
                'glacier',
                'hsl(196, 60%, 85%)',
                'hospital',
                'hsl(340, 32%, 90%)',
                'pitch',
                'hsl(99, 58%, 70%)',
                'sand',
                'hsl(65, 46%, 89%)',
                'school',
                'hsl(50, 46%, 82%)',
                'commercial_area',
                'hsl(50, 60%, 90%)',
                'agriculture',
                'hsl(81, 26%, 81%)',
                ['wood', 'grass', 'scrub'],
                'hsl(81, 25%, 66%)',
                'hsl(35, 13%, 86%)',
              ],
            ],
            'fill-opacity': [
              'interpolate',
              ['linear'],
              ['zoom'],
              5,
              ['match', ['get', 'class'], 'residential', 1, 0],
              6,
              [
                'match',
                ['get', 'class'],
                ['agriculture', 'wood', 'grass', 'scrub'],
                0,
                'glacier',
                0.5,
                1,
              ],
              15,
              [
                'match',
                ['get', 'class'],
                ['wood', 'glacier'],
                0.5,
                ['scrub', 'grass'],
                0.2,
                'residential',
                0,
                1,
              ],
            ],
          },
        },
        {
          id: 'gemeinden',
          type: 'line',
          source: 'gemeinden',
          sourceLayer: 'gemeinden_vorarlberg',
          minzoom: 9,
          layout: {
            // Visibility is set to "none" by default, so we need to set it to "visible"
            visibility: 'visible',
          },
          metaData: {
            'mapbox:group': 'telesis',
          },
          paint: {
            'line-color': 'red',
            'line-width': 1,
            'line-dasharray': [4, 4],
          },
        },
      ],
    },
  },

  // please specify your covered area if you have multiple locations to do waterworks
  areaSwitcher: {
    areas: [
      {
        title: 'Berlin',
        latlng: [13.4046, 52.505],
        zoom: 10,
        pitch: 75,
        bearing: 0,
      },
      {
        title: 'Alexander Platz',
        latlng: [13.4122, 52.5209],
        zoom: 16,
        pitch: 75,
        bearing: 0,
      },
    ],
  },
  // please specify layer name for showing in legend panel.
  legend: {
    targets: {
      hillshade: 'Hillshade',
      Gebäude: 'Gebäude',
      gemeinden: 'Gemeinden',
      haltung: 'Haltungen',
      schacht: 'Schächte',
      leitung: 'Leitungen',
      grundstueck: 'Grundstücke',
      Grundstücke: 'Grundstücksnummern',
      'contour-line': 'Höhenlinien',
      'contour-label': 'Höhe',
      energiebedarf: 'Energiebedarf',
      bevoelkerung: 'Bevölkerung',
      bevoelkerung_extrusion: 'Bevölkerung 3D',
    },
    options: {
      showDefault: true,
      showCheckbox: true,
      onlyRendered: false,
      reverseOrder: true,
    },
  },
  attribution: '© Telesis',
  // please specify layers' name for showing popup with attributes table.
  popup: {
    target: [
      'Gebäude',
      'gemeinden',
      'Grundstücke',
      'contour-line',
      'contour-label',
      // 'contour-line',
      // 'contour-label',
      // 'contour-fill',
      // 'hoehenschichten',
      'bevoelkerung',
      // 'energiebedarf',
    ],
  },
};
export default config;
