import './components/styles/globals.css';
import Mapbox from './components/Map/Mapbox';
// import Navbar from './components/Layout/Navbar';
// import { useEffect, useState } from 'react';
// import NavbarMobile from './components/Layout/NavbarMobile';

function App() {
  return (
    <>
      <Mapbox />
    </>
  );
}

export default App;
