import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import './../styles/globals.css';
import './../styles/Mapbox.css';
import 'mapbox-gl-style-switcher/styles.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import '@watergis/mapbox-gl-pitch-toggle-control/css/styles.css';
import '@watergis/mapbox-gl-legend/css/styles.css';
import 'mapbox-gl-controls/lib/controls.css';
import config from './config';
import '@watergis/mapbox-gl-popup/css/styles.css';
// import MapboxAreaSwitcherControl from '@watergis/mapbox-gl-area-switcher';
import '@watergis/mapbox-gl-area-switcher/css/styles.css';
import '@watergis/mapbox-gl-export/css/styles.css';
import {
  Box,
  // Button,
  Flex,
  IconButton,
  // Button,
  // Drawer,
  // DrawerContent,
  // DrawerOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useColorMode,
  useColorModeValue,
  // useDisclosure,
} from '@chakra-ui/react';
// import BottomDrawer from '../Drawer';
// import Legend from './Legend';
import { parks } from '../../data/markers';
import {
  RiCompass3Line,
  RiLayoutGridFill,
  RiMoonLine,
  // RiListUnordered,
  RiRouteLine,
  // RiSettings3Line,
  RiSunLine,
} from 'react-icons/ri';
import useWindowDimensions from '../hooks/useWindowDimensions';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import MapboxDirections from '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions';
import '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions.css'; // Updating node module will keep css up to date.

interface MapBoxDirections {
  code: string;
  uuid: string;
  waypoints: {
    distance: number;
    name: string;
    location: number[];
  }[];
  routes: {
    distance: number;
    duration: number;
    geometry: {
      coordinates: number[][];
      type: string;
    };
    legs: {
      admins: {
        iso_3166_1: string;
        iso_3166_1_alpha3: string;
      }[];
      distance: number;
      duration: number;
      steps: [];
      summary: string;
      weight: number;
    }[];
    weight: number;
    weight_name: string;
  }[];
}

const Mapbox: React.FC = () => {
  const mapContainer = useRef<any>(null);
  const map = useRef<mapboxgl.Map | null>(null);
  // const map = useRef<mapboxgl.Map | null>(null);
  // const [lng] = useState(config.lng);
  // const [lat] = useState(config.lat);
  // const [zoom] = useState(config.zoom);
  // const { isOpen, onOpen, onClose } = useDisclosure();
  const [visible, setVisible] = useState(true);
  const isExecuting = useRef(false);
  const { width, height } = useWindowDimensions();
  const { colorMode, toggleColorMode } = useColorMode();

  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  let vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  // We listen to the resize event
  window.addEventListener('resize', () => {
    // We execute the same script as before
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });

  // Map creation:
  useEffect(() => {
    if (map.current) return; //stops map from intializing more than once

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      accessToken: config.mapboxAccessToken,
      // style: `https://api.maptiler.com/maps/topo/style.json?key=${API_KEY}`,
      style: 'mapbox://styles/mapbox/streets-v11',
      // style: 'mapbox://styles/mapbox/satellite-v9',
      center: [config.lng, config.lat],
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      maxBounds: config.maxBounds,
      zoom: config.zoom,
      maxPitch: config.maxPitch,
      pitch: config.pitch,
      bearing: config.bearing,
      attributionControl: false,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      projection: 'globe',
    });
  }, []);

  // Load POI's:
  useEffect(() => {
    // This will let you use the .remove() function later on
    if (!('remove' in Element.prototype)) {
      Element.prototype.remove = function () {
        if (this.parentNode) {
          this.parentNode.removeChild(this);
        }
      };
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    function flyToPark(currentFeature) {
      map.current!.flyTo({
        center: currentFeature.geometry.coordinates,
        offset: [0, -100],
        // speed: 0.8,
        curve: 0.6,
        zoom: 18,
      });
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    function createPopUp(currentFeature) {
      const popUps = document.getElementsByClassName('mapboxgl-popup');
      if (popUps[0]) popUps[0].remove();

      // const popup = new mapboxgl.Popup({ closeOnClick: false })
      new mapboxgl.Popup({ closeOnClick: false })
        .setLngLat(currentFeature.geometry.coordinates)

        .setHTML(
          '<img src=' +
            currentFeature.properties.image +
            '>' +
            '<div class="event-card-layout-column justify-space-between">' +
            '<h3>' +
            currentFeature.properties.name +
            '</h3>' +
            '<a class="event-card-link" href=' +
            currentFeature.properties.link +
            '>' +
            currentFeature.properties.person +
            '</a>' +
            '<p>' +
            currentFeature.properties.info +
            '</p>' +
            '</div>',
        )
        .addTo(map.current!);
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    function buildLocationList(data) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      for (let i = 0; i < data.features.length; i++) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const currentFeature = data.features[i];
        // const prop = currentFeature.properties;
        // const prop = currentFeature.properties;

        const listings = document.getElementById('poimapbox-listings');
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const card = listings.appendChild(document.createElement('div'));
        card.className = 'poimapbox-cards';
        card.style.setProperty(
          '--background',
          'url(' + currentFeature.properties.imagetmb + ')',
        );
        // card.style.backgroundImage =
        //   'url(' + currentFeature.properties.imagetmb + ')';

        card.style.cursor = 'pointer';
        // card.onclick = function () {
        //   window.location.href = '#';
        // };

        // Add button?
        const link = card.appendChild(document.createElement('a'));
        link.href = '#';
        link.className = 'cardlink';

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const listing = link.appendChild(document.createElement('div'));
        listing.className = 'amenity-poi';

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        listing.id = 'listing-' + i;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        listing.dataPosition = i;
        listing.innerHTML =
          // '<img class="amenity-poi-img" src=' +
          // currentFeature.properties.imagetmb +
          // '>' +
          '<h2 class="cardtitle">' +
          currentFeature.properties.name +
          '</h2>' +
          '<p class="cardtext">' +
          currentFeature.properties.info +
          '</p>' +
          '<button class="cardbutton">Ansehen</button>';

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        listing.addEventListener('click', function (e) {
          // Update the currentFeature to the Park associated with the clicked link
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const clickedListing = data.features[this.dataPosition];

          // 1. Fly to the point
          flyToPark(clickedListing);

          // 2. Close all other popups and display popup for clicked Park
          createPopUp(clickedListing);

          // 3. Highlight listing in sidebar (and remove highlight for all other listings)
          const activeItem = document.getElementsByClassName('cardlink active');

          if (activeItem[0]) {
            activeItem[0].classList.remove('active');
          }
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          this.parentNode.classList.add('active');
        });
      }
    }

    // This adds the data to the map
    map.current!.on('load', () => {
      // This is where your '.addLayer()' used to be, instead add only the source without styling a layer
      map.current!.addSource('places', {
        type: 'geojson',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        data: parks,
      });
      // Initialize the list
      buildLocationList(parks);
    });

    // This is where your interactions with the symbol layer used to be
    // Now you have interactions with DOM markers instead
    parks.features.forEach(function (marker, i) {
      // Create an img element for the marker
      const el = document.createElement('div');
      el.id = 'poimapbox-marker-' + i;

      if (marker.properties.category === 'Bauwerk') {
        el.className = 'poimapbox-marker-building';
      } else if (marker.properties.category === 'Kultur') {
        el.className = 'poimapbox-marker-culture';
      } else {
        el.className = 'poimapbox-marker';
      }

      // el.className =
      //   marker.properties.category === 'Bauwerk'
      //     ? 'poimapbox-marker-bauwerk'
      //     : 'poimapbox-marker';

      // el.className = 'poimapbox-marker';
      // el.style.backgroundImage =
      //   'url(https://placekitten.com/g/' +
      //   marker.properties.iconSize.join('/') +
      //   '/)';
      // el.style.backgroundImage =
      //   'url(https://placekitten.com/g/' +
      //   marker.properties.iconSize.join('/') +
      //   '/)';
      // el.style.width = marker.properties.iconSize[0] + 'px';
      // el.style.height = marker.properties.iconSize[1] + 'px';

      // el.className = marker.properties.tree=='beech' ? 'marker' : 'marker2';

      // Add markers to the map at all points
      new mapboxgl.Marker(el, { offset: [10, -33] })
        // new mapboxgl.Marker(el, { offset: [15, -10] })
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .setLngLat(marker.geometry.coordinates)
        .addTo(map.current!);

      el.addEventListener('click', function (e) {
        // 1. Fly to the point
        flyToPark(marker);

        // 2. Close all other popups and display popup for clicked Park
        createPopUp(marker);

        // 3. Highlight listing in sidebar (and remove highlight for all other listings)
        const activeItem = document.getElementsByClassName('active');

        e.stopPropagation();
        if (activeItem[0]) {
          activeItem[0].classList.remove('active');
        }

        const listing = document.getElementById('listing-' + i);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        listing.classList.add('active');
      });
    });
  }, []);

  // Load Layers:
  useEffect(() => {
    if (!map.current) return; // wait for map to initialize
    map.current.on('load', () => {
      // add the DEM source as a terrain layer with exaggerated height
      map.current!.setTerrain({
        source: 'mapbox-dem',
        exaggeration: 1,
      });
      map.current!.addControl(
        new mapboxgl.NavigationControl({
          showCompass: true,
          showZoom: true,
          visualizePitch: true,
          // showPosition: false,
        }),
        'top-right',
      );
      // Add the control to the map.

      map.current!.addControl(
        new mapboxgl.GeolocateControl({
          positionOptions: { enableHighAccuracy: true },
          trackUserLocation: true,
        }),
        'top-right',
      );

      map.current!.addSource('places', {
        type: 'geojson',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        data: parks,
      });
      map.current!.resize();
    });

    map.current.on('styledata', () => {
      if (map.current!.getSource('mapbox-dem')) return;
      map.current!.addSource('mapbox-dem', {
        type: 'raster-dem',
        url: `mapbox://mapbox.mapbox-terrain-dem-v1`,
        tileSize: 512,
        maxzoom: 14,
      });
      // add the DEM source as a terrain layer with exaggerated height
      map.current!.setTerrain({
        source: 'mapbox-dem',
        exaggeration: 1,
      });

      // Add new sources and layers
      try {
        if (map.current!.getSource('mapbox-dem')) return;
        map.current!.addSource('mapbox-dem', {
          type: 'raster-dem',
          url: `mapbox://mapbox.mapbox-terrain-dem-v1`,
          tileSize: 512,
          maxzoom: 14,
        });

        map.current!.setTerrain({
          source: 'mapbox-dem',
          exaggeration: 1,
        });
      } catch (error) {
        console.log('Error:', error);
      }
    });
    const mapboxMarker = Array.from(
      document.getElementsByClassName('mapboxgl-marker'),
    );
    if (mapboxMarker != null) {
      map.current!.on('zoom', () => {
        if (map.current!.getZoom() > 12) {
          mapboxMarker.forEach((marker) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            marker.style.height = '70px';
          });
        } else {
          mapboxMarker.forEach((marker) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            marker.style.height = '50px';
          });
        }
      });
    }

    // map.current.on('idle', function () {
    //   map.current!.resize();
    // });
  }, []);

  const handleClick = async () => {
    if (!isExecuting.current) {
      isExecuting.current = true;
      try {
        await setVisible(!visible);
        await map.current!.resize();
      } finally {
        // unblock other clicks
        isExecuting.current = false;
      }
    }
  };

  function changePopupColor() {
    const popupContentColor = document.querySelector(
      '.mapboxgl-popup-content',
    ) as HTMLElement | null;
    const popupCloseColor = document.querySelector(
      '.mapboxgl-popup-close-button',
    ) as HTMLElement | null;
    const popupTipColor = document.querySelector(
      '.mapboxgl-popup-tip',
    ) as HTMLElement | null;

    if (colorMode === 'light') {
      popupContentColor!.style.color = 'white';
      popupContentColor!.style.background = '#1A202C';
      popupCloseColor!.style.background = '#1A202C';
      popupTipColor!.style.borderBottomColor = '#1A202C';
      popupTipColor!.style.borderTopColor = '#1A202C';
    } else if (colorMode === 'dark') {
      popupContentColor!.style.color = 'black';
      popupContentColor!.style.background = 'white';
      popupCloseColor!.style.background = 'white';
      popupTipColor!.style.borderBottomColor = 'white';
      popupTipColor!.style.borderTopColor = 'white';
    }
  }

  function changeButtonColor() {
    const zoomInControl = document.querySelector(
      '.mapboxgl-ctrl-zoom-in',
    ) as HTMLElement | null;
    const zoomOutControl = document.querySelector(
      '.mapboxgl-ctrl-zoom-out',
    ) as HTMLElement | null;
    const compassControl = document.querySelector(
      '.mapboxgl-ctrl-compass',
    ) as HTMLElement | null;
    const geolocateControl = document.querySelector(
      '.mapboxgl-ctrl-geolocate',
    ) as HTMLElement | null;
    const zoomInIcon = zoomInControl?.querySelector(
      '.mapboxgl-ctrl-icon',
    ) as HTMLElement | null;
    const zoomOutIcon = zoomOutControl?.querySelector(
      '.mapboxgl-ctrl-icon',
    ) as HTMLElement | null;
    const compassIcon = compassControl?.querySelector(
      '.mapboxgl-ctrl-icon',
    ) as HTMLElement | null;
    const geolocateIcon = geolocateControl?.querySelector(
      '.mapboxgl-ctrl-icon',
    ) as HTMLElement | null;
    if (colorMode === 'light') {
      map.current!.setStyle('mapbox://styles/mapbox/dark-v9');

      // popupContentColor!.style.color = 'white';
      // popupContentColor!.style.background = '#1A202C';
      // popupCloseColor!.style.background = '#1A202C';

      if (zoomInIcon != null) {
        zoomInIcon.style.background = '#1A202C';
        zoomInControl!.style.outline = '1px solid #505050';
        zoomInIcon.style.backgroundImage = `url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' viewBox='0 0 29 29' xmlns='http://www.w3.org/2000/svg' fill='%23fff'%3E%3Cpath d='M14.5 8.5c-.75 0-1.5.75-1.5 1.5v3h-3c-.75 0-1.5.75-1.5 1.5S9.25 16 10 16h3v3c0 .75.75 1.5 1.5 1.5S16 19.75 16 19v-3h3c.75 0 1.5-.75 1.5-1.5S19.75 13 19 13h-3v-3c0-.75-.75-1.5-1.5-1.5z'/%3E%3C/svg%3E")`;
      }
      if (zoomOutIcon != null) {
        zoomOutIcon.style.background = '#1A202C';
        zoomOutControl!.style.outline = '1px solid #505050';
        zoomOutIcon.style.backgroundImage = `url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' viewBox='0 0 29 29' xmlns='http://www.w3.org/2000/svg' fill='%23fff'%3E%3Cpath d='M10 13c-.75 0-1.5.75-1.5 1.5S9.25 16 10 16h9c.75 0 1.5-.75 1.5-1.5S19.75 13 19 13h-9z'/%3E%3C/svg%3E")`;
      }
      if (compassIcon != null) {
        compassIcon.style.background = '#1A202C';
        compassControl!.style.background = '#1A202C';
        compassControl!.style.outline = '1px solid #505050';
        compassIcon.style.backgroundImage = `  url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' viewBox='0 0 29 29' xmlns='http://www.w3.org/2000/svg' fill='%23fff'%3E%3Cpath d='M10.5 14l4-8 4 8h-8z'/%3E%3Cpath d='M10.5 16l4 8 4-8h-8z' fill='%23ccc'/%3E%3C/svg%3E")`;
      }
      if (geolocateIcon != null) {
        geolocateIcon.style.background = '#1A202C';
        geolocateControl!.style.background = '#1A202C';
        geolocateControl!.style.outline = '1px solid #505050';
        geolocateIcon.style.backgroundImage = `url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' fill='%23fff'%3E%3Cpath d='M10 4C9 4 9 5 9 5v.1A5 5 0 005.1 9H5s-1 0-1 1 1 1 1 1h.1A5 5 0 009 14.9v.1s0 1 1 1 1-1 1-1v-.1a5 5 0 003.9-3.9h.1s1 0 1-1-1-1-1-1h-.1A5 5 0 0011 5.1V5s0-1-1-1zm0 2.5a3.5 3.5 0 110 7 3.5 3.5 0 110-7z'/%3E%3Ccircle cx='10' cy='10' r='2'/%3E%3C/svg%3E")`;
      }
    } else if (colorMode === 'dark') {
      map.current!.setStyle('mapbox://styles/mapbox/streets-v11');
      // popupContentColor!.style.color = 'black';
      // popupContentColor!.style.background = 'white';
      // popupCloseColor!.style.background = 'white';

      if (zoomInIcon != null) {
        zoomInIcon.style.background = '#fff';
        zoomInControl!.style.outline = 'none';
        zoomInIcon.style.backgroundImage = `url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' viewBox='0 0 29 29' xmlns='http://www.w3.org/2000/svg' fill='%23333'%3E%3Cpath d='M14.5 8.5c-.75 0-1.5.75-1.5 1.5v3h-3c-.75 0-1.5.75-1.5 1.5S9.25 16 10 16h3v3c0 .75.75 1.5 1.5 1.5S16 19.75 16 19v-3h3c.75 0 1.5-.75 1.5-1.5S19.75 13 19 13h-3v-3c0-.75-.75-1.5-1.5-1.5z'/%3E%3C/svg%3E")`;
      }
      if (zoomOutIcon != null) {
        zoomOutIcon.style.background = '#fff';
        zoomOutControl!.style.outline = 'none';
        zoomOutIcon.style.backgroundImage = `url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' viewBox='0 0 29 29' xmlns='http://www.w3.org/2000/svg' fill='%23333'%3E%3Cpath d='M10 13c-.75 0-1.5.75-1.5 1.5S9.25 16 10 16h9c.75 0 1.5-.75 1.5-1.5S19.75 13 19 13h-9z'/%3E%3C/svg%3E")`;
      }
      if (compassIcon != null) {
        compassIcon.style.background = '#fff';
        compassControl!.style.background = '#fff';
        compassControl!.style.outline = 'none';
        compassIcon.style.backgroundImage = `  url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' viewBox='0 0 29 29' xmlns='http://www.w3.org/2000/svg' fill='%23333'%3E%3Cpath d='M10.5 14l4-8 4 8h-8z'/%3E%3Cpath d='M10.5 16l4 8 4-8h-8z' fill='%23ccc'/%3E%3C/svg%3E")`;
      }
      if (geolocateIcon != null) {
        geolocateIcon.style.background = '#fff';
        geolocateControl!.style.background = '#fff';
        geolocateControl!.style.outline = 'none';
        geolocateIcon.style.backgroundImage = `url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' fill='%23333'%3E%3Cpath d='M10 4C9 4 9 5 9 5v.1A5 5 0 005.1 9H5s-1 0-1 1 1 1 1 1h.1A5 5 0 009 14.9v.1s0 1 1 1 1-1 1-1v-.1a5 5 0 003.9-3.9h.1s1 0 1-1-1-1-1-1h-.1A5 5 0 0011 5.1V5s0-1-1-1zm0 2.5a3.5 3.5 0 110 7 3.5 3.5 0 110-7z'/%3E%3Ccircle cx='10' cy='10' r='2'/%3E%3C/svg%3E")`;
      }
    }
  }

  const directions = new MapboxDirections<MapBoxDirections>({
    accessToken: mapboxgl.accessToken,
    unit: 'metric',
    profile: 'mapbox/driving',
  });

  let toggleStatus = false;
  function toggleDirectionMenu() {
    if (toggleStatus === false) {
      map.current!.addControl(directions, 'top-left');
      toggleStatus = true;
    } else {
      map.current!.removeControl(directions);
      toggleStatus = false;
    }
    // directionMenu ? addDirectionMenu() : removeDirectionMenu();
  }

  // const [isActive, setActive] = useState(false);

  // const toggleClass = () => {
  //   setActive(!isActive);
  // };

  // const [toggle, setToggle] = useState(false);

  // const toggleFunction = () => {
  //   if (toggle) {
  //     removeDirectionMenu();
  //     setToggle(false);
  //   } else {
  //     addDirectionMenu();
  //     setToggle(true);
  //   }
  // };

  // const addDirectionMenu = () => {
  //   console.log(map.current!);
  //   map.current!.addControl(directions, 'top-left');
  //   setActive(!isActive);
  // };
  // const removeDirectionMenu = () => {
  //   console.log(map.current!);
  //   map.current!.removeControl(directions);
  //   setActive(!isActive);
  // };

  return (
    <Box height={height} width={width}>
      <Box
        className="map-wrap"
        sx={{
          height: visible ? '50%' : 'calc(100% - 56px)',
        }}
      >
        <Box
          ref={mapContainer}
          className="poimapbox-map"
          zIndex={1}
          sx={{
            height: visible
              ? 'calc(var(--vh, 1vh) * 50)'
              : 'calc((var(--vh, 1vh) * 100) - 56)',
          }}
        />
        {/* <BottomDrawer /> */}
      </Box>
      <Box
        sx={{
          display: visible ? 'block' : 'none',
          height: visible ? 'calc(calc(var(--vh, 1vh) * 50) - 56px)' : '0',
        }}
        className="poimapbox-sidebar"
        mb="56px"
        position="fixed"
      >
        <Tabs isFitted variant="enclosed" align="center">
          {/* <div className="poimapbox-heading"> */}
          <TabList
            border="none"
            bg="#9e0529"
            color="white"
            minHeight={{ base: '30px', md: '36px', lg: '42px' }}
            lineHeight={{ base: '30px', md: '36px', lg: '42px' }}
            px="10px"
            pt="5px"
            overflowY="hidden"
            sx={{
              scrollbarWidth: 'none',
              '::-webkit-scrollbar': {
                display: 'none',
              },
            }}
          >
            {/* <Tab
              px={{ base: '8px', md: '16px' }}
              fontSize={{ base: '14px', sm: '14px', md: '18px' }}
              _selected={{
                color: useColorModeValue('#00436B', 'white'),
                bg: useColorModeValue('white', 'gray.800'),
                fontWeight: '600',
              }}
            >
              Alle
            </Tab> */}
            <Tab
              px={{ base: '8px', md: '16px' }}
              fontSize={{ base: '14px', sm: '14px', md: '18px' }}
              _selected={{
                color: useColorModeValue('#00436B', 'white'),
                bg: useColorModeValue('white', 'gray.800'),
                fontWeight: '600',
              }}
            >
              Architektur
            </Tab>
            <Tab
              px={{ base: '8px', md: '16px' }}
              fontSize={{ base: '14px', sm: '14px', md: '18px' }}
              _selected={{
                color: useColorModeValue('#00436B', 'white'),
                bg: useColorModeValue('white', 'gray.800'),
                fontWeight: '600',
              }}
            >
              Sport
            </Tab>
            <Tab
              px={{ base: '8px', md: '16px' }}
              fontSize={{ base: '14px', sm: '14px', md: '18px' }}
              _selected={{
                color: useColorModeValue('#00436B', 'white'),
                bg: useColorModeValue('white', 'gray.800'),
                fontWeight: '600',
              }}
            >
              Kunst
            </Tab>
            <Tab
              px={{ base: '8px', md: '16px' }}
              fontSize={{ base: '14px', sm: '14px', md: '18px' }}
              _selected={{
                color: useColorModeValue('#00436B', 'white'),
                bg: useColorModeValue('white', 'gray.800'),
                fontWeight: '600',
              }}
            >
              Unternehmen
            </Tab>
          </TabList>
          {/* </div> */}
          <TabPanels mt="4px">
            {/* <TabPanel minH="50vh">
              <div id="poimapbox-listings" className="poimapbox-listings" />
            </TabPanel> */}
            <TabPanel minH="50vh">
              <div id="poimapbox-listings" className="poimapbox-listings" />
            </TabPanel>

            <TabPanel minH="50vh">Filter Sport</TabPanel>
            <TabPanel minH="50vh">Filter Kunst</TabPanel>
            <TabPanel minH="50vh">Filter Unternehmen</TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
      <Box
        bg={useColorModeValue('gray.100', 'gray.900')}
        // display={{ base: 'flex', md: 'none' }}
        bottom="0"
        position="fixed"
        w="100%"
        px={4}
        zIndex="overlay"
      >
        <Flex h={14} alignItems={'center'} justifyContent={'space-between'}>
          <Flex
            flex={{ base: 1, md: 'auto' }}
            alignItems="center"
            justify={{ base: 'center', md: 'center' }}
          >
            <IconButton
              as={'a'}
              variant={'solid'}
              //   variant={'link'}
              href={'#'}
              aria-label="Map"
              fontSize="2em"
              icon={<RiCompass3Line />}
            />
          </Flex>
          <Flex
            flex={{ base: 1, md: 'auto' }}
            alignItems="center"
            justify={{ base: 'center', md: 'center' }}
            onClick={handleClick}
          >
            <IconButton
              // as={'a'}
              // href={'#'}
              variant={visible ? 'solid' : 'link'}
              // variant={'link'}

              aria-label="List"
              fontSize="1.6em"
              icon={<RiLayoutGridFill />}
            />
          </Flex>
          <Flex
            flex={{ base: 1, md: 'auto' }}
            alignItems="center"
            justify={{ base: 'center', md: 'center' }}
            onClick={toggleDirectionMenu}
          >
            <IconButton
              // as={'a'}
              // href={'#'}

              variant={'link'}
              // variant={isActive ? 'solid' : 'link'}
              aria-label="Directions"
              fontSize="2em"
              icon={<RiRouteLine />}
            />
          </Flex>
          <Flex
            flex={{ base: 1, md: 'auto' }}
            alignItems="center"
            justify={{ base: 'center', md: 'center' }}
            onClick={() => {
              toggleColorMode();
              changeButtonColor();
              changePopupColor();
            }}
          >
            <IconButton
              variant={'link'}
              aria-label="Map"
              fontSize="2em"
              icon={colorMode === 'light' ? <RiMoonLine /> : <RiSunLine />}
            />
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};
export default Mapbox;
