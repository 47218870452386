import { extendTheme } from '@chakra-ui/react';
export const styles = extendTheme({
  global: () => ({
    body: {
      // fontFamily: 'body',
      //   bg: useColorModeValue('gray.200', 'gray.700'),
      color: 'primary.200',
      overflow: 'hidden',
    },
  }),
});
